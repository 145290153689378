export const LOGO = require("./img/logo.png");
export const CIRCLE = require("./img/circle.png");
export const CIRCLE_OK = require("./img/circle_ok.png");
export const AMERICAN_LOGO = require("./img/american.png");
export const AURA_LOGO = require("./img/aura.png");
export const BANES_LOGO = require("./img/banes.png");
export const ELO_LOGO = require("./img/elo.png");
export const HIPER_CARD_LOGO = require("./img/hiper-card.png");
export const HIPER_LOGO = require("./img/hiper.png");
export const JBC_LOGO = require("./img/jbc.png");
export const MASTER_LOGO = require("./img/mastercard.png");
export const VISA_LOGO = require("./img/visa.png");
export const INFOR = require("./img/infor.png");
export const CARD = require("./img/card.png");
export const SUCCESS = require("./img/success.png");
export const PLUS_ICON = require("./img/plus-icon.png");
export const MINUS_ICON = require("./img/minus-icon.png");