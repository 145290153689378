import React, { useEffect, useRef, useState } from "react";
import ModalLoadingPayment from "../ModalLoadingPayment";
import { baseURL } from "../../providers/config";
import Time from "../../assets/img/time.webp";
import {
  ICaptor,
  ICourseClass,
  IPaymentLink,
  ITrainee,
  ITransaction,
} from "../../pages/CustomerCkeckout";
import CourseClassInformation from "../CourseClassInformation";
import Logo from "../Logo";
import * as image from "../../assets";
import PersonalData from "../PersonalData";
import Checkout from "../Checkout";

export interface ICheckoutFormProps {
  transaction: ITransaction;
  captor?: ICaptor | null;
  courseClass?: ICourseClass | null;
  disabled?: boolean;
  trainee?: ITrainee | null;
  paymentLink: IPaymentLink | null;
}

export interface IAccount {
  name?: string;
  email?: string;
  phone?: string;
  taxpayer?: string;
  sex?: string;
}

const CheckoutForm = ({
  transaction,
  courseClass,
  disabled,
  paymentLink
}: ICheckoutFormProps) => {
  const siteURL =
    baseURL === "https://mutase-api.devaribox.co/api/v1"
      ? "https://mutase.devaribox.co"
      : "https://mutase.com.br";
  const [step, setStep] = useState<number>(0);
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(false);
  const [listaTreinandos, setListaTreinandos] = useState<ITrainee[]>([
    {taxpayer: ''}
  ]);

  const [contador, setContador] = useState<string | null>(null);
  const timeClock = useRef<NodeJS.Timeout | undefined>();
  
  
  useEffect(() => {
    if(paymentLink?.json_list_treinee) {
      setListaTreinandos(paymentLink.json_list_treinee);
      setStep(paymentLink.status);
    }
  }, [paymentLink])

  useEffect(() => {
    var duration = 60 * 15; // Converter para segundos
    startTimer(duration); // iniciando o timer
  }, []);

  function startTimer(duration: number): void {
    var timer: number = duration;
    var minutes: string;
    var seconds: string;

    timeClock.current = setInterval(function () {
      minutes = (timer / 60).toFixed(0).padStart(2, "0");
      seconds = (timer % 60).toFixed(0).padStart(2, "0");
      setContador(minutes + ":" + seconds);

      if (timer > 0) {
        --timer;
      } else {
        clearTimeout(timeClock.current);
        timeClock.current = undefined;
      }
    }, 1000);
  }

  const renderComponent = () => {
    switch (step) {
      case 0:
        return (
          <PersonalData
            setStep={setStep}
            paymentLink={paymentLink}
            listaTreinandos={listaTreinandos}
            setListaTreinandos={setListaTreinandos}
          />
        );
      case 1:
        return (
          <Checkout
            disabled={
              disabled ? true : courseClass ? courseClass.sold_out : true
            }
            price={Number(paymentLink?.value)}
            transaction={null} 
            setListaTreinandos={setListaTreinandos}
            courseClass={courseClass}
            setSep={setStep}
            paymentId={paymentLink?.id}
            installments={paymentLink?.installments}
          />
        );
      case 2:
        return (
          <div id="success-container">
            <img src={image.SUCCESS} />
            <br />
            <h4>Inscrição realizada!</h4>
            <a href={siteURL}>Voltar ao site</a>
          </div>
        );
      default:
        return (
          <div id="success-container">
            <img src={image.SUCCESS} />
            <br />
            <h4>Pagamento realizado!</h4>
            <a href={siteURL}>Voltar ao site</a>
          </div>
        );
    }
  };

  return (
    <div id="wrapper">
      <div className="courseClass">
        <div className="contador">
          <p id="timer">{contador}</p>
          <img
            src={Time}
            width="26"
            height="26"
            alt="Ícone de um relógio"
            loading="eager"
          />
          <span>
            {contador !== "00:00"
              ? "Oferta por tempo limitado"
              : "O tempo acabou!"}
          </span>
        </div>
        <div>
          <div>
            <Logo />
          </div>
          <div className="column">
            {paymentLink?.course_class ? (
              <CourseClassInformation 
              courseClass={paymentLink?.course_class} 
              value={paymentLink.value}
              captor={paymentLink.captor}
            />
            ) : null}
          </div>
        </div>
      </div>
      <hr />
      
      {renderComponent()}
      <ModalLoadingPayment isOpen={isLoadingPayment} />
    </div>
  );
};

export default CheckoutForm;
