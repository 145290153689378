import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { addToast } from "../Notification";
import ModalLoadingPayment from "../ModalLoadingPayment";
import { validAccount, validCPF } from "../../utils";
import {
  checkForInscription,
  checkForTaxpayer,
  getByTaxpayer,
  createInscription,
} from "../../providers/trainee";
import { useRef } from "react";
import { IAccount } from "../CheckoutForm";
import {
  ICaptor,
  ICourseClass,
  IInscriptions,
  IPaymentLink,
  ITrainee,
} from "../../pages/CustomerCkeckout";
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { updatePaymentLink } from "../../providers/course";

interface IPersonDataProps {
  disabled?: boolean;
  paymentLink: IPaymentLink | null;
  handleUpdate?: (value: IAccount) => void;
  initialState?: IAccount | null;
  courseClass?: ICourseClass | null;
  captor?: ICaptor | null;
  setStep: (num: number) => void;
  listaTreinandos: ITrainee[];
  setListaTreinandos: (value: ITrainee[]) => void;
}

function PersonalData({
  paymentLink,  
  setStep,
  listaTreinandos,
  setListaTreinandos,
}: IPersonDataProps) {
  const [loading, setLoading] = useState(false);

  const handleChangeValue = async (field: string, value: string, index: number) => {
    const treinando: any = listaTreinandos[index];
    treinando[field] = value;

    if (field === "taxpayer") {
      const rawCpf = treinando[field].replace(/[^\d]/g, '');
      const isValid = validCPF(treinando[field]);
      
      if (isValid) {
        setLoading(true);

        try {
          const response = await getByTaxpayer(rawCpf);
          
          treinando.id = response.id;
          treinando.name = response.name;
          treinando.email = response.email;
          treinando.phone = response.phone;
          treinando.sex = response.sex;

        } catch (error) {
          treinando.name = '';
          treinando.email = '';
          treinando.phone = '';
          treinando.sex = '';
        }

        setLoading(false);
      } else if (rawCpf.length === 11 && !isValid) {
        addToast("error", "CPF Inválido", "Verifique se o CPF é valido");
      }
    }

    atualizaState();
  };

  const adicionarDiv = () => {
    listaTreinandos.push({ taxpayer: '' });
    atualizaState();
  };

  const removeDiv = (index: number) => {
    listaTreinandos.splice(index, 1);
    atualizaState();
  };

  const atualizaState = () => {
    const copyShadow = JSON.parse(JSON.stringify(listaTreinandos));
    setListaTreinandos(copyShadow);
  }

  const validaDadosTreinandos = () => {
    atualizaLinkListaJsonTreinandos();
    try {
      if (listaTreinandos.length !== paymentLink?.qtd_inscriptions) {
        throw new Error(`Você precisa adicionar as informações de ${paymentLink?.qtd_inscriptions} treinandos`);
      }
      for (const treinando of listaTreinandos) {
        const possuiCampoVazio = Object.values(treinando).some(valor => valor === undefined || valor === null || valor === "");
        if (possuiCampoVazio) {
          throw new Error("Verifique se esta faltando dados em algum treinando");
        }
        let itensFilterCPF = listaTreinandos.filter((item) => item.taxpayer == treinando.taxpayer)
        if (itensFilterCPF.length > 1) {
          throw new Error("Existem mais de um treinando com o mesmo CPF");
        }
        let itensFilterEmail = listaTreinandos.filter((itreiando) => itreiando.email == treinando.email)
        if (itensFilterEmail.length > 1) {
          throw new Error("Existem mais de um treinando com o mesmo email");
        }
      }
    } catch (error: any) {
      addToast("error", "Falta Dados", error.message);
      return;
    }
    atualizaLinkStatus();
    setStep(1);
  }

  const atualizaLinkListaJsonTreinandos = () => {
    const listTreinandos = listaTreinandos.map(tre => {
      return {
        ...tre,
        taxpayer: tre.taxpayer?.replace(/[^\d]/g, '')
      }
    });
    const strListaTreinandos = JSON.stringify(listTreinandos);
    const payload = {
      json_list_treinee: strListaTreinandos
    }
    if(paymentLink) {
      updatePaymentLink(paymentLink?.id, payload);
    }
  }

  const atualizaLinkStatus = () => {
    const payload = { status: 1 }
    
    if(paymentLink) {
      updatePaymentLink(paymentLink?.id, payload);
    }
  }
  
  return (
    <form autoComplete="off" className="box">
      <p>Digite os nomes dos participantes do treinamento</p>
      <div>
        {listaTreinandos.map((treinando, index) => (
          <div className="mb-3 w-100" key={index}>
            <div className="d-flex align-items-baseline">
              <button
                className="btn btn-link"
                color="link"
                type="button"
                onClick={() => removeDiv(index)}
              >
                <AiOutlineCloseCircle color="red" style={{ fontSize: 18 }} />
              </button>
              <div className="Personal w-100">
                <InputMask
                  value={treinando.taxpayer}
                  disabled={loading}
                  placeholder="CPF"
                  mask="999.999.999-99"
                  type="tel"
                  onChange={({ target }) =>
                    handleChangeValue("taxpayer", target.value, index)
                  }
                />
                {Object.hasOwn(treinando, "name") ? <>
                  <input
                    type="text"
                    id={`name-${index}`}
                    value={treinando.name}
                    placeholder="Nome Completo"
                    onChange={({ target }) =>
                      handleChangeValue("name", target.value, index)
                    }
                  />
                  <input
                    value={treinando.email}
                    placeholder="E-mail"
                    type="email"
                    onChange={({ target }) =>
                      handleChangeValue("email", target.value, index)
                    }
                  />
                  <InputMask
                    value={treinando.phone}
                    placeholder="Celular com DDD"
                    mask="(99)99999-9999"
                    type="tel"
                    onChange={({ target }) =>
                      handleChangeValue("phone", target.value, index)
                    }
                  />
                  <select
                    style={{ marginBottom: "14px" }}
                    className="customSelect"
                    value={treinando.sex}
                    onChange={({ target }) =>
                      handleChangeValue("sex", target.value, index)
                    }
                  >
                    <option value="">Sexo</option>
                    <option value="MASCULINO">Masculino</option>
                    <option value="FEMININO">Feminino</option>
                  </select>
                </> : null }
              </div>
            </div>
          </div>
        ))}
        {paymentLink && paymentLink.qtd_inscriptions > listaTreinandos.length ? <button
          className="btn btn-link mb-3"
          color="link"
          type="button"
          onClick={adicionarDiv}
        >
          <AiOutlinePlusCircle style={{ fontSize: 22 }} />
        </button> : null }
      </div>
      <div className="box">
        <button
          type="button"
          // onClick={getTrainee}
          // disabled={disabled}
          onClick={validaDadosTreinandos}
          className="confirm-button"
        >
          Continuar
        </button>
      </div>
    </form>
  );
}

export default PersonalData;
