
import provider, { authorization } from "./config";

export const getCourseClass = async (id: string) => {
    try {
        const response = await provider.get(`class/${id}/`);
        return response.data;
    } catch (err) {
        throw Error("Turma inválida");
    }
}

export const getPaymentLink = async (id: string) => {
    try {
        const response = await provider.get(`link-payment/${id}/`);
        return response.data;
    } catch (err) {
        throw Error("Link inválido");
    }
}

export const updatePaymentLink = async (id: number, payload: any) => {
    try {
        await provider.patch(`link-payment/${id}/`, payload, {
            headers: {
                Authorization: `Token ${authorization}`
            }
        });
    } catch (error) {
        console.log(error);
    }
}