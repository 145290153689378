import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CheckoutForm from "../components/CheckoutForm";
import { getTransaction } from "../providers/transaction";
import { addToast } from "../components/Notification";
import { getCaptor } from "../providers/captor";
import { getCourseClass, getPaymentLink } from "../providers/course";

function useQuery() {
  const [searchParams] = useSearchParams();
  return searchParams;
}

export interface ITransaction {
  captor_id?: ICaptor[];
  code?: string;
  comprovante?: string;
  date?: string;
  description?: string;
  id: number;
  inscription?: IInscriptions;
  inscription_value?: string;
  installments?: string;
  payment_type?: string;
  seller_id?: ISeller[];
  status?: string;
  value: string;
}

export interface ICaptor {
  id: number;
  head_trainer?: number;
  profile?: number;
  name?: string;
}

export interface IInscriptions {
  id: number;
  name?: string;
  name_tag?: string;
  status?: string;
  observation?: string;
  link?: string;
  value: string;
  nf_id?: string;
  course_class?: ICourseClass;
  trainee?: ITrainee;
  payed?: boolean;
}

export interface ICourseClass {
  aparece_no_site?: boolean;
  approved?: boolean;
  bonus_percentage?: string;
  certificate_email_body?: string;
  certificate_template?: string;
  checkout_type?: number;
  comission_percentage?: number;
  course?: number;
  cover?: string;
  event_location?: string;
  final_date?: string;
  franchise?: number;
  head_trainer?: number;
  id: number;
  inactive?: boolean;
  initial_date?: string;
  name?: string;
  online?: boolean;
  payment_redirect?: string;
  reference_value?: number;
  sold_out?: boolean;
  status?: number;
}

export interface ITrainee {
  captor?: number;
  email?: string;
  id?: number;
  name?: string;
  phone?: string;
  sex?: string;
  taxpayer?: string;
}

export interface ISeller {
  seller_id?: string;
}

export interface IPaymentLink {
  id: number;
  name?: string;
  course_class: ICourseClass;
  captor?: ICaptor | null;
  json_list_treinee?: ITrainee[];
  qtd_inscriptions: number;
  status: number;
  value: string;
  installments?: string;
}

const CustomerCheckout = () => {
  const query = useQuery();
  
  const [disabled, setDisabled] = useState<boolean>(false);
  const [paymentLink, setPaymentLink] = useState<IPaymentLink | null>(null);
  const [transaction, setTransaction] = useState<ITransaction>({
    code: "",
    comprovante: "",
    date: "",
    description: "",
    id: 0,
    inscription_value: "",
    installments: "",
    payment_type: "",
    status: "",
    value: ""
  });

  useEffect(() => {
    loadContent();
  }, []);

  const loadContent = async () => {
    const linkPaymentId = query.get("payment");

    if (linkPaymentId) {
      await loadPaymentLink(linkPaymentId);
    }
  };

  const loadPaymentLink = async (linkPaymentId: string) => {
    try {
      const response = await getPaymentLink(linkPaymentId);
      let linkPayment: IPaymentLink = {
        id: response.id,
        course_class: response.course_class,
        qtd_inscriptions: response.qtd_inscriptions,
        status: response.status,
        value: response.value,
        captor: response.captor,
        name: response.name,
        installments: response.installments
      };

      if (response.json_list_treinee) {
        const listaTreinandos: ITrainee[] = JSON.parse(response.json_list_treinee);
        linkPayment.json_list_treinee = listaTreinandos;
      } else {
        const listTrainandos: ITrainee[] = [{ taxpayer: '' }];
        linkPayment.json_list_treinee = listTrainandos;
      }

      setPaymentLink(linkPayment);
    } catch (error: any) {
      return addToast("error", "Erro", error.message);
    }
  }

  return (
    <CheckoutForm
      disabled={disabled}
      paymentLink={paymentLink}
      transaction={transaction}
    />
  );
};

export default CustomerCheckout;
