import React from "react";
import { toReal } from "../../utils";
import { ICaptor, ICourseClass } from "../../pages/CustomerCkeckout";

interface ICourseClasseInformationProps {
  courseClass?: ICourseClass;
  value: string;
  captor?: ICaptor | null;
}
function CourseClassInformation({
  courseClass,
  value,
  captor
}: ICourseClasseInformationProps) {

  return (
    <div className="courseInfoContainer">
      <div className="courseInfoGroup">
        <strong>Turma</strong>
        <p>{courseClass?.name}</p>
      </div>
      {captor ? (
        <div className="courseInfoGroup">
          <strong>Consultor</strong>
          <p>{captor?.name}</p>
        </div>
      ) : null}
      <div className="courseInfoGroup">
        <strong>Valor à pagar</strong>
        <p className="primary">
          {/* R${" "} */}
          {courseClass ? toReal(+value) : "R$ 0,00"}
        </p>
      </div>
      {/* <div className="courseInfoGroup">
                <strong>Vagas Esgotadas</strong>
                <p>{courseClass.sold_out ? "Sim" : "Não"}</p>
            </div> */}
    </div>
  );
}

export default CourseClassInformation;
