import axios from "axios";

const currentUrl = window.location.href;
let url = "";
let token = "";
// Verifica Qual base está ativo
if (currentUrl.includes("mutase.com.br")) {
  url = "https://api.mutase.com.br";
  token = "3e4bfa517847e6efb8a2ecf7cb59415d0c696f3f";
} else if (currentUrl.includes("devaribox.co")) {
  url = "https://mutase-api.devaribox.co";
  token = "e037cd552efccc002d131a6404785157aede0dfe";
} else if (currentUrl.includes("localhost:300")) {
  // url = "https://api.mutase.com.br";
  // token = "3e4bfa517847e6efb8a2ecf7cb59415d0c696f3f"
  token = "e037cd552efccc002d131a6404785157aede0dfe";
  // url = "https://mutase-api.devaribox.co";
  url = "http://localhost:8000";
  // token = "91932ac086a4d741e39f79c0418d94c593056319";
}
export const baseURL = url;
export const authorization = token;
export default axios.create({
  baseURL: `${baseURL}/api/v1/`,
});

// Forma antiga de fazer o baseURL
// export const baseURL = "http://localhost:8000/api/v1";
// export const baseURL = "https://mutase-api.devaribox.co/api/v1";
// export const baseURL = "https://api.mutase.com.br/api/v1";

// export default axios.create({
//     baseURL
// });
