import { Store } from "react-notifications-component";

export function addToast(type: string, title: string, message: string) {
  switch (type) {
    case "success":
      Store.addNotification({
        type: "success",
        title: title,
        message: message,
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          showIcon: true,
        },
      });
      break;
    case "error":
      Store.addNotification({
        type: "danger",
        title: title,
        message: message,
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          showIcon: true,
        },
      });
      break;
    default:
      break;
  }
}
