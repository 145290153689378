import provider from "./config";
import { authorization } from "./config"

export const getTransaction = async (id: string) => {
  try {
    const response = await provider.get(`transaction/${id}/`,
      { headers: { Authorization: `Token ${authorization}` } });
    return response.data;
  } catch (err) {
    console.log(err)
    throw Error("Transação inválida");
  }
}

export const transactionPayment = async (payload: object) => {

  try {
    const response = await provider.post("transaction/multi-payment/", payload);
    return response.data;
  } catch (err: any) {
    console.log(err);
    throw Error("Ocorreu um erro ao realizar o pagamento");
  }
}