import * as image from '../../assets'

const Logo = () => {

  return (
    <header>
      <img src={`${image.LOGO}`} alt='Logo Mutase'/>
    </header> 
  );
}

export default Logo;