import React, { useState, useEffect } from "react";
// import CurrencyInput from "react-currency-input";
import InputMask from "react-input-mask";

import ModalCCV from "../ModalCCV";
import { addToast } from "../Notification";
import Cards, { Focused } from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { getCurrentDeviceInfor, validCPF } from "../../utils";

import {
  toReal
} from "../../utils";
import * as image from "../../assets";
import {
  ICourseClass
} from "../../pages/CustomerCkeckout";
import { transactionPayment } from "../../providers/transaction";

interface ICheckoutProps {
  disabled?: boolean;
  price?: number;
  transaction: any;
  courseClass?: ICourseClass | null;
  setListaTreinandos: (value: any) => void;
  setSep: (value: any) => void;
  paymentId?: number;
  installments?: string;
}

function Checkout({
  disabled,
  price,
  transaction,
  courseClass,
  setSep,
  paymentId,
  installments,
}: ICheckoutProps) {
  const year = new Date().getFullYear();
  const [modal, setModal] = useState(false);
  const [focused, setFocused] = useState<Focused | undefined>();
  const [loading, setLoading] = useState(false);
  const [dadosCartao, setDadosCartao] = useState({
    holder_name: "",
    exp_month: "",
    exp_year: "",
    number: "",
    cvc: "",
    installment_number: "1",
    holder_taxpayer: "",
  });

  useEffect(() => {
    if(installments) {
      setDadosCartao({
        ...dadosCartao,
        installment_number: installments
      })
    }
  }, [installments])

  const handleChangeValue = (field: string, value: any) => {
    setDadosCartao({ ...dadosCartao, [field]: value });
  };

  const handleTriggerModal = () => setModal(!modal);

  const changeFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case "name":
        setFocused("name");
        break;
      case "cvc":
        setFocused("cvc");
        break;
    }
  };

  const changeFocusSelect = (e: React.FocusEvent<HTMLSelectElement>) => {
    switch (e.target.name) {
      case "expiry":
        setFocused("expiry");
        break;
    }
  };

  const handleRealizaPagamento = async () => {
    const isValid = validDadosCartao();
    const deviceInfor = getCurrentDeviceInfor();

    if (isValid) {
      setLoading(true);
      const payload = { 
        ...dadosCartao, 
        payment: paymentId,
        deviceInfor: deviceInfor
      }
      try {
        const response = await transactionPayment(payload);
        setSep(2);
      } catch (error: any) {
        addToast("error", "Falha", error.message);
      }
      setLoading(false);
    }
  }

  const validDadosCartao = () => {
    const possuiCampoVazio = Object.values(dadosCartao).some(valor => valor === undefined || valor === null || valor === "");
    const isValid = validCPF(dadosCartao.holder_taxpayer);
    
    if (possuiCampoVazio) {
      addToast("error", "Dados inválidos", "Verifique se existe algum campo vazio!");
      return ;
    }

    if (!isValid) {
      addToast("error", "CPF Inválido", "Verifique se o CPF é valido");
      return ;
    }

    return true;
  }

  const generateInstallments = () => {
    let options = [];
    if (installments){
      for (let index=1; index <= +installments; index++){
        options.push(
          <option value={index} selected={index == +installments ? true : false}>
            {price && index == 1
              ? `${index}x de ${toReal(price)}`
              : price && index > 1 
              ? `${index}x de ${toReal(price / index)}`
              : null}
          </option>
        )
      }
    }
    return options;
  }

  const _CURRENT_YEAR = new Date().getFullYear();

  return (
    <>
      <form
        autoComplete="off"
        className=""
      >
        <div className="box">
          <div className="card">
            <div className="rowCard">
              <div>
                <input
                  type="tel"
                  placeholder="Número do Cartão"
                  onChange={({ target }) =>
                    handleChangeValue("number", target.value)
                  }
                  name="number"
                  onFocus={changeFocus}
                />
                <input
                  placeholder="Nome do Titular do Cartão"
                  type="text"
                  onChange={({ target }) =>
                    handleChangeValue("holder_name", target.value)
                  }
                  name="name"
                  onFocus={changeFocus}
                />

                <div className="row-input">
                  <div className="customSelectContainer">
                    <select
                      style={{ marginRight: 10 }}
                      className="customSelect"
                      name="expiry"
                      onChange={({ target }) =>
                        handleChangeValue("exp_month", target.value)
                      }
                      onFocus={changeFocusSelect}
                    >
                      <option selected disabled hidden>
                        MÊS
                      </option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                    <select
                      className="customSelect"
                      name="expiry"
                      onChange={({ target }) =>
                        handleChangeValue("exp_year", target.value)
                      }
                      onFocus={changeFocusSelect}
                    >
                      <option selected disabled hidden>
                        ANO
                      </option>
                      {Array.from(Array(15), (_, index) => (
                        <option
                          key={_CURRENT_YEAR + index}
                          value={_CURRENT_YEAR + index}
                        >
                          {_CURRENT_YEAR + index}
                        </option>
                      ))}
                    </select>
                    <div className="cvvInput">
                      <input
                        type="tel"
                        placeholder="CVV"
                        maxLength={4}
                        name="cvc"
                        onChange={({ target }) =>
                          handleChangeValue("cvc", target.value)
                        }
                        onFocus={changeFocus}
                      />
                      <img
                        onClick={() => setModal(true)}
                        src={image.INFOR}
                        id="infor-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Cards
                  name={dadosCartao.holder_name}
                  number={dadosCartao.number}
                  expiry={`${dadosCartao.exp_month}/${dadosCartao.exp_year}`}
                  cvc={dadosCartao.cvc}
                  focused={focused}
                />
              </div>
            </div>
            <InputMask
              placeholder="CPF do Titular do Cartão"
              mask="999999999-99"
              type="tel"
              onChange={({ target }) =>
                handleChangeValue("holder_taxpayer", target.value)
              }
              name="holder_taxpayer"
              onFocus={changeFocus}
            />
            <div>
              <select
                className="customSelect"
                onChange={({ target }) =>
                  handleChangeValue("installment_number", target.value)
                }
              >
                {transaction ? (
                  <>
                    {Number(transaction?.installments) >= 1 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={1}
                        selected={
                          transaction.installments === "1" ? true : false
                        }
                      >
                        {price
                          ? `1x de ${toReal(price)}`
                          : `1x de ${toReal(Number(transaction.value))}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 2 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={2}
                        selected={
                          transaction.installments === "2" ? true : false
                        }
                      >
                        {price
                          ? `2x de ${toReal(price / 2)}`
                          : `2x de ${toReal(Number(transaction.value) / 2)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 3 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={3}
                        selected={
                          transaction.installments === "3" ? true : false
                        }
                      >
                        {price
                          ? `3x de ${toReal(price / 3)}`
                          : `3x de ${toReal(Number(transaction.value) / 3)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 4 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={4}
                        selected={
                          transaction.installments === "4" ? true : false
                        }
                      >
                        {price
                          ? `4x de ${toReal(price / 4)}`
                          : `4x de ${toReal(Number(transaction.value) / 4)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 5 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={5}
                        selected={
                          transaction.installments === "5" ? true : false
                        }
                      >
                        {price
                          ? `5x de ${toReal(price / 5)}`
                          : `5x de ${toReal(Number(transaction.value) / 5)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 6 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={6}
                        selected={
                          transaction.installments === "6" ? true : false
                        }
                      >
                        {price
                          ? `6x de ${toReal(price / 6)}`
                          : `6x de ${toReal(Number(transaction.value) / 6)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 7 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={7}
                        selected={
                          transaction.installments === "7" ? true : false
                        }
                      >
                        {price
                          ? `7x de ${toReal(price / 7)}`
                          : `7x de ${toReal(Number(transaction.value) / 7)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 8 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={8}
                        selected={
                          transaction.installments === "8" ? true : false
                        }
                      >
                        {price
                          ? `8x de ${toReal(price / 8)}`
                          : `8x de ${toReal(Number(transaction.value) / 8)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 9 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={9}
                        selected={
                          transaction.installments === "9" ? true : false
                        }
                      >
                        {price
                          ? `9x de ${toReal(price / 9)}`
                          : `9x de ${toReal(Number(transaction.value) / 9)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 10 ||
                    transaction.installments === "" ||
                    transaction.installments === "0" ? (
                      <option
                        value={10}
                        selected={
                          transaction.installments === "10" ? true : false
                        }
                      >
                        {price
                          ? `10x de ${toReal(price / 10)}`
                          : `10x de ${toReal(Number(transaction.value) / 10)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 11 ? (
                      <option
                        value={11}
                        selected={
                          transaction.installments === "11" ? true : false
                        }
                      >
                        {price
                          ? `11x de ${toReal(price / 11)}`
                          : `11x de ${toReal(Number(transaction.value) / 11)}`}
                      </option>
                    ) : null}
                    {Number(transaction.installments) >= 12 ? (
                      <option
                        value={12}
                        selected={
                          transaction.installments === "12" ? true : false
                        }
                      >
                        {price
                          ? `12x de ${toReal(price / 12)}`
                          : `12x de ${toReal(Number(transaction.value) / 12)}`}
                      </option>
                    ) : null}
                  </>
                ) : installments ? (
                  generateInstallments()
                ) : (
                  <>
                    <option value={1}>
                      {price
                        ? `1x de ${toReal(price)}`
                        : transaction  
                        ? `1x de ${toReal(Number(transaction.value))}`
                        : null}
                    </option>
                    <option value={2}>
                      {price
                        ? `2x de ${toReal(price / 2)}`
                        : `2x de ${toReal(Number(transaction.value) / 2)}`}
                    </option>
                    <option value={3}>
                      {price
                        ? `3x de ${toReal(price / 3)}`
                        : `3x de ${toReal(transaction.value / 3)}`}
                    </option>
                    <option value={4}>
                      {price
                        ? `4x de ${toReal(price / 4)}`
                        : `4x de ${toReal(transaction.value / 4)}`}
                    </option>
                    <option value={5}>
                      {price
                        ? `5x de ${toReal(price / 5)}`
                        : `5x de ${toReal(transaction.value / 5)}`}
                    </option>
                    <option value={6}>
                      {price
                        ? `6x de ${toReal(price / 6)}`
                        : `6x de ${toReal(transaction.value / 6)}`}
                    </option>
                    <option value={7}>
                      {price
                        ? `7x de ${toReal(price / 7)}`
                        : `7x de ${toReal(transaction.value / 7)}`}
                    </option>
                    <option value={8}>
                      {price
                        ? `8x de ${toReal(price / 8)}`
                        : `8x de ${toReal(transaction.value / 8)}`}
                    </option>
                    <option value={9}>
                      {price
                        ? `9x de ${toReal(price / 9)}`
                        : `9x de ${toReal(transaction.value / 9)}`}
                    </option>
                    <option value={10}>
                      {price
                        ? `10x de ${toReal(price / 10)}`
                        : `10x de ${toReal(transaction.value / 10)}`}
                    </option>
                  </>
                )}
              </select>
            </div>
          </div>
          <div className="details">
            <span>Detalhes da Compra</span>
            <hr />
            <div>
              <p>Total: </p>
              <p>{price ? toReal(+price) : null}</p>
            </div>
          </div>
          <div className="confirmButtonContainer">
            <button
              disabled={loading}
              className="confirm-button"
              type="button"
              onClick={handleRealizaPagamento}
            >
              Pagar agora
            </button>
          </div>
        </div>
      </form>
      <div className="footer">
        <p>Mutase &copy; {year} - Todos os direitos reservados</p>
      </div>
      <ModalCCV modal={modal} triggerModal={handleTriggerModal} />
    </>
  );
}

export default Checkout;